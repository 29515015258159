import { OnChatBotClient } from "@gameon/web/react";
import { StaticImage } from "gatsby-plugin-image";
import React from "react";

import ContactForm from "../../components/contact-form/contact-form";
import Decor from "../../components/decor/decor";
import HeroText from "../../components/hero-text/hero-text";
import LastWords from "../../components/last-words/last-words";
import Layout from "../../components/layout/layout";
import Seo from "../../components/seo/seo";
import Stats from "../../components/stats/stats";
import TestimonyQuote from "../../components/testimony-quote/testimony-quote";

import "./case-study.scss";

const IS_BROWSER = typeof window !== "undefined";

class CaseStudy extends React.Component {
  render() {
    return (
      <div className="case-study-page case-study-page--arsenal theme-yellow">
        <Layout>
          {IS_BROWSER && (
            <OnChatBotClient
              clientId={"94ad01ef-00bf-471a-8ebe-cdb66314190a"}
              displayMode="WIDGET"
            />
          )}

          <HeroText>
            <StaticImage
              className="hero-text__background--mobile"
              alt=""
              src="../../../static/images/hero-text/case-study.png"
              placeholder="blurred"
              quality={100}
              layout={"fullWidth"}
            />
            <StaticImage
              className="hero-text__background--computer"
              alt=""
              src="../../../static/images/hero-text/case-study.png"
              placeholder="blurred"
              quality={100}
              layout={"fullWidth"}
            />
            <section className="hero-text__text">
              <div className="hero-text__content">
                <div className="hero-text__title heading-1">Arsenal</div>
                <p className="hero-text__caption heading-6">
                  GameOn delivered a custom chat solution that required zero
                  ongoing management, launching the foundation for a new channel
                  of fan communication.
                </p>
              </div>
            </section>
          </HeroText>

          <div className="text-content">
            <h2 className="heading-3">
              An authentic personality that is Arsenal
            </h2>
          </div>

          <Decor type="medium" />
          <Decor type="rotate--counter-clockwise" />

          <div className="text-content">
            <p className="heading-6">
              <a className="underline--inline" href="https://www.arsenal.com/">
                Arsenal FC
              </a>{" "}
              is a professional football club based in Islington, London,
              England, that plays in the Premier League, the top-flight of
              English football. With over 200 years of history, the Gunners
              digital marketing team has consistently focused on innovation,
              embracing new technologies to engage an incredibly loyal fanbase
              while expanding their reach to new audiences around the world.
            </p>
            <p className="heading-6">
              Arsenal partnered with GameOn to increase brand engagement with
              fans through an innovative, and uniquely personalized messaging
              experience.
            </p>
            <div className="heading-4">
              A deep connection with the individual fan
            </div>
            <p className="body-text">
              Arsenal wanted to create an authentic 1:1 relationship directly
              with fans by owning interactions from start to finish. This
              required a user experience that provided content and value to a
              wide range of fans types, felt natural to the user, and had an
              authentic and relatable Arsenal voice. Arsenal also aimed to grow
              their viewership and better understand the specific types of
              content and interactions their fans find most engaging. This
              required a flexible and evolving set of features that make it easy
              to track reach, understand engagement, and measure the success of
              changes within targeted user groups.
            </p>
          </div>

          <div className="align-right">
            <Decor type="short" />
          </div>

          <Stats
            left={{
              metric: ">3X",
              heading: "Engagement on match days",
              description:
                "Driven by a combination of accessible real-time content and personalized broadcast experiences.",
            }}
            right={{
              metric: "90%",
              heading: "Of users become WAUs",
              description:
                "Weekly active users helped to generate an average of over 1M messages per week within the first 6 months.",
            }}
          />
          <Stats
            left={{
              metric: '"Thx!"',
              heading: "Was sent over 100K times",
              description:
                "Exemplifies the positive sentiment and connection users created with the Arsenal experience.",
            }}
            right={{
              metric: "10X",
              heading: "The industry average CTR",
              description:
                "Web content shared in bot contributed to a significant increase in traffic to strategic Arsenal web properties.",
            }}
          />

          <div className="text-content">
            <div className="success">
              <div className="decor-top">
                <Decor type="rotate--clockwise" />
              </div>
              <p className="heading-2">Success</p>
              <p className="body-text">
                GameOn and Arsenal created a personality driven chat experience
                in only four weeks that allowed fans to connect intimately with
                the brand on their own terms. The experience made an immediate
                impact on fan engagement, especially on match days as fans
                around the world leveraged the real-time broadcast content from
                an Arsenal perspective. GameOn and Arsenal were able to gather
                insights from interaction data almost immediately, deploying a
                range of new features over the first few months that spoke
                directly to the behavior of its users in chat. The Arsenal
                chatbot is available on the following channels:
              </p>
              <ul>
                <li className="bullets body-text">
                  <a
                    className="underline--inline"
                    href="https://www.messenger.com/t/20669912712/"
                  >
                    Messenger
                  </a>
                </li>
                <li className="bullets body-text">
                  <a
                    className="underline--inline"
                    href="https://slack.com/apps/ABQ94GR52-arsenal"
                  >
                    Slack
                  </a>
                </li>
                <li className="bullets body-text">
                  <a
                    className="underline--inline"
                    href="https://t.me/officialarsenalbot"
                  >
                    Telegram
                  </a>
                </li>
              </ul>
            </div>
          </div>
          <div className="quote testimony-quote">
            <TestimonyQuote
              quote="We're constantly looking at ways to bring fans content that makes their experience even more entertaining. Working with GameOn to craft the bot is the next step in us developing a hub for fans that feels intuitively part of the Arsenal voice and experience. Fans feel a personal connection to Arsenal, and Al is more important than ever for us to give fans a personalised experience."
              author="Tom Hines"
              profileImage="/images/testimony-quote/arsenal-logo-mono.jpg"
              position="Senior Content Manager"
              company="Arsenal Media"
            />
          </div>

          <LastWords>
            Get in touch today
            <br className="desktop-line-break" /> for a custom chat experience
          </LastWords>

          <ContactForm action="https://formsubmit.co/df104fca19fab87fb541d94105effa78" />
        </Layout>
      </div>
    );
  }
}

export const Head = ({ location }) => {
  const seoMeta = {
    metaTitle: "Arsenal Case Study",
    metaDescription:
      "Arsenal partnered with GameOn to increase brand engagement with fans through an innovative, and uniquely personalized messaging experience.",
    locale: "en-US",
    slug: location.pathname,
    image: {
      url:
        "https://assets-dash.s3.amazonaws.com/arsenal-case-study-meta-img.png",
      width: 1200,
      height: 810,
    },
  };
  return <Seo {...seoMeta} />;
};

export default CaseStudy;
